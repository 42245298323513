import { CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { compact, debounce, find, noop } from 'lodash';
import React, { ChangeEvent, Fragment, isValidElement, useCallback, useEffect, useState } from 'react';
import Api from '../../../../../../api';
import { TCity } from '../../../../../../api/city';
import { TLocation } from '../../../../../../reducers/recentLocationsByProduct';

interface IProps {
  initial?: TLocation;
  onChange: (latitude: string, longitude: string, city: string) => void
}

const optionLabel = (option: TCity) => {
  const { name, state, country } = option;
  return compact([name, state, country]).join(', ');
};

const DEBOUNCE_INTERVAL = 300;

const CityLookup: React.FC<IProps> = ({ initial, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [ready, setReady] = useState(false);
  const [initialCity, setInitialCity] = useState<TCity | null>(null);
  const [cities, setCities] = useState<Array<TCity>>([]);
  const [inputValue, setInputValue] = useState<string>('');

  const fetchCities = useCallback((name: string, config?: { signal: AbortSignal }) => {
    return Api.City.list({ name, limit: 10 }, config);
  }, []);

  const onInputChange = useCallback(debounce((event: ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
  }, DEBOUNCE_INTERVAL), [fetchCities]);

  const onCitySelect = (event: ChangeEvent<{}>, value: TCity | null) => {
    if (value) {
      onChange(value.latitude, value.longitude, value.name);
    }
  };

  useEffect(() => {
    if (!initial) {
      setReady(true);
      return;
    }

    const { name, latitude, longitude } = initial;

    fetchCities(name).then(cities => {
      const found = find(cities, city => city.latitude === latitude && city.longitude === longitude);

      setInitialCity(found ? found : {
        altitude: 0,
        country: '',
        state: '',
        latitude: '0',
        longitude: '0',
        name
      });

      setReady(true);
    });
  }, [initial, fetchCities]);

  useEffect(() => {
    setLoading(true);

    const controller = new AbortController();
    const { signal } = controller;

    fetchCities(
      inputValue,
      { signal: signal }
    ).then(cities => {
      setCities(cities);
      setLoading(false);
    }).catch(noop);

    return () => {
      if (signal && controller.abort) {
        controller.abort();
      }
    }
  }, [inputValue]);

  if (!ready) {
    return null;
  }

  return (
    <Autocomplete
      autoComplete
      includeInputInList
      filterSelectedOptions
      defaultValue={initialCity}
      renderInput={params => {
        const autocompleteAdornment = params.InputProps.endAdornment;
        return (
          <TextField
            {...params}
            label="City"
            fullWidth
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {isValidElement(autocompleteAdornment) && (
                    <InputAdornment
                      position="end"
                      className={autocompleteAdornment.props.className}
                      style={{ height: 'auto' }}
                    >
                      {loading && <CircularProgress color="inherit" size={14} />}
                      {autocompleteAdornment.props.children}
                    </InputAdornment>
                  )}
                </Fragment>
              )
            }}
          />
        );
      }}
      options={cities}
      getOptionLabel={optionLabel}
      onInputChange={onInputChange}
      onChange={onCitySelect}
      popupIcon={null}
    />
  );
};

export default CityLookup;
